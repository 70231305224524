import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "container mx-auto px-6" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MainLayoutHeader = _resolveComponent("MainLayoutHeader")!
  const _component_main_layout_hero_section = _resolveComponent("main-layout-hero-section")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_MainLayoutFooter = _resolveComponent("MainLayoutFooter")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_MainLayoutHeader),
    (_ctx.showHeroSection)
      ? (_openBlock(), _createBlock(_component_main_layout_hero_section, { key: 0 }))
      : _createCommentVNode("", true),
    _createElementVNode("main", _hoisted_1, [
      _createVNode(_component_router_view)
    ]),
    _createVNode(_component_MainLayoutFooter)
  ], 64))
}